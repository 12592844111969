import React from "react";
import { Box, Typography } from "@material-ui/core";

import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FiYoutube } from "@react-icons/all-files/fi/FiYoutube";

import { makeStyles } from "@material-ui/core/styles";

import { getLocaleValue } from "../../../helpers/get-locale-value";
import { extractImagePathUrl } from "../image/image-url-helper";
import Navigation from "../navigation";

const useStylesSocialMediaAnalytic = makeStyles({
  analyticsWrapper: {
    background: "white",
    color: props => props.socialMedia.font_icon_background_color,
    borderRadius: "10px",
    boxShadow: "0px 0px 15px #555!important",
    marginTop: "250px", //tally with min-height of mascot
  },

  analyticsIcon: {
    backgroundColor: props => props.socialMedia.font_icon_background_color,
    color: props => props.socialMedia.font_icon_color,
    fontSize: "2em",
  },

  socialMediaMascot: {
    top: 0,
    right: 0,
    backgroundImage: props => props.mascotImage,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "bottom",
    minWidth: "250px",
    minHeight: "250px",
    position: "absolute",
  },
});

const useStylesSocialMedia = makeStyles({
  socialMediaIcon: {
    background: props => props.socialMedia.font_icon_background_color,
    color: props => props.socialMedia.font_icon_color,
    fontSize: "2em",
    borderRadius: "50%",
    boxShadow: "8px 8px 8px rgba(100,100,100,0.8)!important",
  },
});

const useStyles = makeStyles({
  socialMedia: {
    position: "absolute",
    zIndex: 9,
    fontSize: "0.8em",
    top: "910px",
    right: "10%",
    transform: "scale(0.67)",

    "@media screen and (max-width: 1680px)": {
      right: "0%",
    },
  },

  socialMediaIcons: {
    position: "absolute",
    left: "-55%",
    top: "30%",
    zIndex: 1,
  },
});

const socialMediaView = ({ locale, data }) => {
  // Rendering icons based on cms settings, icons are currently hard coded
  const renderIcons = data => {
    switch (data.font_icon) {
      case "fa/FaFacebookF":
        return <FaFacebookF />;
      case "fa/FaInstagram":
        return <FaInstagram />;
      case "fi/FiYoutube":
        return <FiYoutube />;
    }
  };

  const classess = useStyles();

  return (
    <Box display="flex" className={classess.socialMedia}>
      <Box
        display="flex"
        alignItems="center"
        className={classess.socialMediaIcons}
      >
        {Array.from({ length: data.length }, (_, i) => {
          var socialMedia = data[i];

          const socialMediaClasses = useStylesSocialMedia({
            socialMedia: socialMedia,
          });

          return (
            <a
              key={i}
              href={getLocaleValue(locale, socialMedia.url_variants).content}
              target="_blank"
            >
              <Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width={50}
                  height={50}
                  m={1}
                  className={socialMediaClasses.socialMediaIcon}
                >
                  {renderIcons(socialMedia)}
                </Box>
              </Box>
            </a>
          );
        })}
      </Box>
      <Box>
        {Array.from({ length: data.length }, (_, i) => {
          var socialMedia = data[i];
          var socialMediaAnalytics = socialMedia.analytics[0];

          const socialMediaAnalyticsClasses = useStylesSocialMediaAnalytic({
            socialMedia: socialMedia,
            mascotImage: `url(${extractImagePathUrl(
              socialMedia.mascot_image
            )})`,
          });

          if (socialMedia.display_analytics) {
            return (
              <Box key={`socialMediaMascot` + i}>
                <Box
                  className={socialMediaAnalyticsClasses.socialMediaMascot}
                ></Box>
                <Box
                  display="flex"
                  justifyContent="space-around"
                  pl={2}
                  pr={2}
                  className={socialMediaAnalyticsClasses.analyticsWrapper}
                >
                  <Box
                    display="flex"
                    pl={1.75}
                    pr={1.75}
                    justifyContent="center"
                    alignItems="center"
                    className={socialMediaAnalyticsClasses.analyticsIcon}
                  >
                    {renderIcons(socialMedia)}
                  </Box>
                  <Box
                    className={socialMediaAnalyticsClasses.analyticsLabel}
                    py={1}
                    px={2}
                    textAlign="center"
                  >
                    <Navigation
                      to={
                        getLocaleValue(locale, socialMedia.url_variants).content
                      }
                      target="_blank"
                    >
                      <Typography noWrap variant="body2">
                        {
                          getLocaleValue(
                            locale,
                            socialMediaAnalytics.label_variants
                          ).content
                        }
                      </Typography>
                      <Typography variant="h6" noWrap>
                        {socialMediaAnalytics.value}
                      </Typography>
                    </Navigation>
                  </Box>
                </Box>
              </Box>
            );
          }
        })}
      </Box>
    </Box>
  );
};

export default socialMediaView;
