import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import { useSelector } from "react-redux";

import NewsBoardView from "./news-board-view";
import ResponsiveWrapper from "../../shared-components/responsive-wrapper";

const NewsBoardController = ({
  data,
  margin,
  padding,
  zIndex,
  filter,
  anchorLink,
  displayTitle,
  numberPerPage,
  titleVariants,
}) => {
  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  const [latestNews, setLatestNews] = useState([]);

  useEffect(() => {
    const news = _.filter(data, newsPost => {
      const { announced_at } = newsPost;

      if (moment().utc().isAfter(moment.utc(announced_at))) {
        return true;
      } else {
        return false;
      }
    });

    const sortedNews = _.orderBy(
      news,
      [
        newsPost => {
          return newsPost.announced_at;
        },
        newsPost => {
          return newsPost.created_at;
        },
      ],
      ["desc", "desc"]
    );

    setLatestNews(sortedNews);
  }, [data]);

  return (
    <ResponsiveWrapper
      DesktopComponent={
        <NewsBoardView
          margin={margin}
          padding={padding}
          zIndex={zIndex}
          locale={locale}
          titleVariants={titleVariants}
          numberPerPage={numberPerPage}
          displayTitle={displayTitle}
          anchorLink={anchorLink}
          news={latestNews}
        />
      }
      TabletComponent={
        <NewsBoardView
          margin={margin}
          padding={padding}
          zIndex={zIndex}
          locale={locale}
          titleVariants={titleVariants}
          numberPerPage={numberPerPage}
          displayTitle={displayTitle}
          anchorLink={anchorLink}
          news={latestNews}
        />
      }
      PhoneComponent={
        <NewsBoardView
          margin={margin}
          padding={padding}
          zIndex={zIndex}
          locale={locale}
          titleVariants={titleVariants}
          numberPerPage={numberPerPage}
          displayTitle={displayTitle}
          anchorLink={anchorLink}
          news={latestNews}
        />
      }
    />
  );
};

export default NewsBoardController;
