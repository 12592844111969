import React from "react";

import BackdropVideoViewDesktop from "./backdrop-video-view-desktop";
import BackdropVideoViewTablet from "./backdrop-video-view-tablet";
import BackdropVideoViewPhone from "./backdrop-video-view-phone";

import ResponsiveWrapper from "../responsive-wrapper";

const BackdropVideoController = ({
  margin,
  padding,
  zIndex,
  media,
  downloadChannels,
}) => {
  return (
    <ResponsiveWrapper
      DesktopComponent={
        <BackdropVideoViewDesktop
          media={media}
          margin={margin}
          padding={padding}
          zIndex={zIndex}
          downloadChannels={downloadChannels}
        />
      }
      TabletComponent={
        <BackdropVideoViewTablet
          media={media}
          margin={margin}
          padding={padding}
          zIndex={zIndex}
          downloadChannels={downloadChannels}
        />
      }
      PhoneComponent={
        <BackdropVideoViewPhone
          media={media}
          margin={margin}
          padding={padding}
          zIndex={zIndex}
          downloadChannels={downloadChannels}
        />
      }
    />
  );
};

export default BackdropVideoController;
