import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Box } from "@material-ui/core";
import Img from "gatsby-image/withIEPolyfill";
import Image from "../image";

const useStyles = makeStyles({
  decorationWrapper: {
    position: "absolute",
    minHeight: "500px",
    transform: "translateY(-650px)",
    margin: "0 auto",
    width: "670px",
    left: "calc(50% - 670px/2)",
    zIndex: 0,
  },
  backdropImage: {
    width: "100%",
    position: "absolute",
    objectFit: "cover",
    top: 0,
  },
});

const BackdropDecorationView = ({ image, margin, padding, zIndex }) => {
  const { padding_top, padding_bottom, padding_left, padding_right } = padding;
  const { margin_top, margin_bottom, margin_left, margin_right } = margin;

  const classes = useStyles();
  return (
    <Box
      pt={padding_top}
      pb={padding_bottom}
      pl={padding_left}
      pr={padding_right}
      mt={margin_top}
      mb={margin_bottom}
      ml={margin_left}
      mr={margin_right}
      zIndex={zIndex}
      display="flex"
      justifyContent="center"
      className={classes.decorationWrapper}
    >
      <Image
        loading="eager"
        // objectFit="contain"
        // objectPosition="top center"
        imageObject={image}
        className={classes.backdropImage}
      />
    </Box>
  );
};

export default BackdropDecorationView;
