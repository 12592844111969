import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Img from "gatsby-image";
import { Box } from "@material-ui/core";
import { getLocaleValue } from "../../../helpers/get-locale-value";
import Image from "../image";

const useStyles = makeStyles({
  button: {
    width: "150px",
  },
  buttonImage: {
    marginBottom: 5,
    maxWidth: "150px",
  },
});

const DownloadChannelsView = ({ locale, data }) => {
  const classes = useStyles();
  var hasDownloadNox = false;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
      justifyContent="flex-start"
      p={5}
      width={`100%`}
      zIndex={9}
      position="relative"
    >
      {Array.from({ length: data.length }, (_, i) => {
        var item = data[i];
        hasDownloadNox =
          item.name == "download-nox" || hasDownloadNox ? true : false;

        return (
          <a
            href={item.download_url}
            key={i}
            className={classes.button}
            target="_blank"
            style={{
              display: "block",
              marginTop:
                item.name == "download-nox" ||
                (!hasDownloadNox && item.name == "download-ld")
                  ? "15px"
                  : 0,
            }}
          >
            <Image
              loading="eager"
              imageObject={
                getLocaleValue(locale, item.download_icon_variants).media
              }
              className={classes.buttonImage}
            />
          </a>
        );
      })}
    </Box>
  );
};

export default DownloadChannelsView;
