import React from "react";

import ResponsiveWrapper from "../../responsive-wrapper";

import BlockDecoImageDesktop from "./block-deco-image-view-desktop";
import BlockDecoImageTablet from "./block-deco-image-view-tablet";
import BlockDecoImagePhone from "./block-deco-image-view-phone";

const BlockDecoImageController = ({ data, locale }) => {
  return (
    <ResponsiveWrapper
      DesktopComponent={<BlockDecoImageDesktop data={data} locale={locale} />}
      TabletComponent={<BlockDecoImageTablet data={data} locale={locale} />}
      PhoneComponent={<BlockDecoImagePhone data={data} locale={locale} />}
    />
  );
};

export default BlockDecoImageController;
