import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Img from "gatsby-image";
import { Box } from "@material-ui/core";
import { getLocaleValue } from "../../../helpers/get-locale-value";
import Image from "../image";

const useStyles = makeStyles({
  button: {
    width: "150px",
  },
  buttonImage: {
    marginBottom: 5,
    maxWidth: "150px",
  },
});

const DownloadChannelsView = ({ locale, data }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
      justifyContent="flex-start"
      p={5}
      width={`100%`}
      zIndex={2}
      position="relative"
    >
      {Array.from({ length: data.length }, (_, i) => {
        var item = data[i];

        return (
          item.name !== "download-nox" &&
          item.name !== "download-ld" && (
            <a
              href={item.download_url}
              key={i}
              className={classes.button}
              target="_blank"
            >
              <Image
                imageObject={
                  getLocaleValue(locale, item.download_icon_variants).media
                }
                className={classes.buttonImage}
              />
            </a>
          )
        );
      })}
    </Box>
  );
};

export default DownloadChannelsView;
