import React from "react";
import { useSelector } from "react-redux";
import ResponsiveWrapper from "../shared-components/responsive-wrapper";

import PinnedCardsItemView from "./pinned-cards-item-view";

const PinnedCardsItemController = ({ data }) => {
  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  return (
    <ResponsiveWrapper
      DesktopComponent={<PinnedCardsItemView locale={locale} data={data} />}
      TabletComponent={<PinnedCardsItemView locale={locale} data={data} />}
      PhoneComponent={<PinnedCardsItemView locale={locale} data={data} />}
    />
  );
};
export default PinnedCardsItemController;
