import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Img from "gatsby-image";

import { Box } from "@material-ui/core";
import DownloadChannelController from "../download-channels/download-channels-controller";

const BackdropImageViewTablet = ({
  image,
  margin,
  padding,
  zIndex,
  downloadChannels,
}) => {
  const { padding_top, padding_bottom, padding_left, padding_right } = padding;
  const { margin_top, margin_bottom, margin_left, margin_right } = margin;

  const useStyles = makeStyles({
    backdropImageWrapper: {
      position: "relative",
      zIndex: 0,
      height: 0,
      minHeight: "500px",
      overflow: "hidden",
    },
    backdropImage: {
      width: "100%",
      height: "100%",
      position: "absolute",
      objectFit: "cover",
      top: 0,
    },
  });

  const classes = useStyles();
  return (
    <Box
      pt={padding_top}
      pb={padding_bottom}
      pl={padding_left}
      pr={padding_right}
      mt={margin_top}
      mb={margin_bottom}
      ml={margin_left}
      mr={margin_right}
      zIndex={zIndex}
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      className={classes.backdropImageWrapper}
    >
      <DownloadChannelController data={downloadChannels} />
      <Image imageObject={image} className={classes.backdropImage} />
    </Box>
  );
};

export default BackdropImageViewTablet;
