import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Box } from "@material-ui/core";

import Parser from "html-react-parser";
import { getLocaleValue } from "../../../../helpers/get-locale-value";
import Image from "../../image";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    position: "relative",
    transform: props => props.transform,
    justifyContent: "center",
  },
  image: {
    width: "100%",
  },
  textWrapper: {
    marginTop: "-5%",
    marginLeft: "20%",
    maxWidth: "600px",
    color: "#595758",
    textAlign: "center",
    fontSize: "1.5em",

    "& h1": {
      marginBottom: "10px",
      fontSize: "2.8rem",
    },
  },
});

const BlockCharacterDesktop = ({
  locale,
  data,
  padding_top,
  padding_left,
  padding_bottom,
  padding_right,
  margin_top,
  margin_left,
  margin_bottom,
  margin_right,
  z_index,
  translateX,
  translateY,
  translateZ,
}) => {
  const {
    male_image,
    female_image,
    title_variants,
    description_variants,
  } = data;
  const classes = useStyles({
    transform: `translateX(${translateX}) translateY(${translateY}) translateZ(${translateZ})`,
  });

  return (
    <Box
      width="100%"
      pt={padding_top}
      pl={padding_left}
      pb={padding_bottom}
      pr={padding_right}
      mt={margin_top}
      ml={margin_left}
      mb={margin_bottom}
      mr={margin_right}
      zIndex={z_index}
      className={classes.wrapper}
      pt="5%"
      pb={4}
    >
      <Box display="inline-block" width="50%" mr="-8%" mt="-5%">
        <Image
          imageObject={female_image}
          className={classes.image}
          alt="Ninja Class Female"
        />

        <Box className={classes.textWrapper}>
          <Box width="100%" className={classes.headerTitle}>
            {Parser(getLocaleValue(locale, title_variants).html_content)}
          </Box>
          <Box width="100%">
            {Parser(getLocaleValue(locale, description_variants).html_content)}
          </Box>
        </Box>
      </Box>
      <Box display="inline-block" width="50%" ml="-5%">
        <Image
          imageObject={male_image}
          className={classes.image}
          alt="Ninja Class Male"
        />
      </Box>
    </Box>
  );
};

export default BlockCharacterDesktop;
