import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Box } from "@material-ui/core";
import { getLocaleValue } from "../../../../helpers/get-locale-value";
import Image from "../../image";

const useStyles = makeStyles({
  wrapper: {
    position: "relative",
    transform: props => props.transform,
  },
  nonActive: {
    filter: "grayscale(1)",
  },
});

const BlockTabContentViewPhone = ({
  locale,
  data,
  padding_top,
  padding_left,
  padding_bottom,
  padding_right,
  margin_top,
  margin_left,
  margin_bottom,
  margin_right,
  z_index,
  translateX,
  translateY,
  translateZ,
}) => {
  const { tabs } = data;
  const classes = useStyles({
    transform: `translateX(${translateX}) translateY(${translateY}) translateZ(${translateZ})`,
  });

  const [activeTab, setActiveTab] = useState(0);
  const videoURL =
    process.env.GATSBY_ENV == "development" ? "http://localhost:1337" : "";

  return (
    <Box
      width="100%"
      pt={padding_top}
      pl={padding_left}
      pb={padding_bottom}
      pr={padding_right}
      mt={margin_top}
      ml={margin_left}
      mb={margin_bottom}
      mr={margin_right}
      zIndex={z_index}
      className={classes.wrapper}
    >
      <Box display="flex" justifyContent="center">
        {tabs?.map((n, i) => (
          <Box
            className={activeTab != i ? classes.nonActive : ""}
            onClick={() => setActiveTab(i)}
            key={`tab-button-${i}`}
          >
            <Image
              loading="eager"
              imageObject={getLocaleValue(locale, n.tab_variant).image}
            />
          </Box>
        ))}
      </Box>
      <Box>
        {tabs?.map((n, i) => (
          <Box display={activeTab != i ? `none` : ""} key={`tab-content-${i}`}>
            <Box
              width="100%"
              pt={n.spatial.padding_top ? n.spatial.padding_top : ""}
              pl={n.spatial.padding_left ? n.spatial.padding_left : ""}
              pb={n.spatial.padding_bottom ? n.spatial.padding_bottom : ""}
              pr={n.spatial.padding_right ? n.spatial.padding_right : ""}
              mt={n.spatial.margin_top ? n.spatial.margin_top : ""}
              ml={n.spatial.margin_left ? n.spatial.margin_left : ""}
              mb={n.spatial.margin_bottom ? n.spatial.margin_bottom : ""}
              mr={n.spatial.margin_right ? n.spatial.margin_right : ""}
              zIndex={n.spatial.z_index ? n.spatial.z_index : ""}
              zIndex={n.border ? n.border : ""}
            >
              {!n.reverse ? (
                <video
                  controls
                  // loop
                  // autoPlay={`autoplay`}
                  muted
                  playsInline
                >
                  <source
                    src={
                      getLocaleValue(locale, tab_variant).video.url.startsWith(
                        "/"
                      )
                        ? `${
                            process.env.GATSBY_ENV == "development"
                              ? "http://localhost:1337"
                              : ""
                          }${getLocaleValue(locale, tab_variant).video.url}`
                        : `${process.env.GATSBY_CDN_IMAGE_URL}${
                            getLocaleValue(locale, tab_variant).video.hash
                          }${getLocaleValue(locale, tab_variant).video.ext}`
                    }
                    type={`${getLocaleValue(locale, tab_variant).video.mime}`}
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                ""
              )}
              <Box>
                {getLocaleValue(locale, tab_variant).title && (
                  <Box width="100%">
                    {Parser(getLocaleValue(locale, tab_variant).title)}
                  </Box>
                )}

                {getLocaleValue(locale, tab_variant).description && (
                  <Box width="100%">
                    {Parser(getLocaleValue(locale, tab_variant).description)}
                  </Box>
                )}
              </Box>
              {n.reverse ? (
                <video
                  controls
                  // loop
                  // autoPlay={`autoplay`}
                  muted
                  playsInline
                >
                  <source
                    src={
                      getLocaleValue(locale, tab_variant).video.url.startsWith(
                        "/"
                      )
                        ? `${
                            process.env.GATSBY_ENV == "development"
                              ? "http://localhost:1337"
                              : ""
                          }${getLocaleValue(locale, tab_variant).video.url}`
                        : `${process.env.GATSBY_CDN_IMAGE_URL}${
                            getLocaleValue(locale, tab_variant).video.hash
                          }${getLocaleValue(locale, tab_variant).video.ext}`
                    }
                    type={`${getLocaleValue(locale, tab_variant).video.mime}`}
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                ""
              )}
              {n.divider && (
                <Box display="flex" justifyContent="center">
                  <Image
                    loading="eager"
                    imageObject={n.divider.backgroundImage}
                  />
                </Box>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default BlockTabContentViewPhone;
