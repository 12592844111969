import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Parser from "html-react-parser";
import { Box } from "@material-ui/core";
import { getLocaleValue } from "../../../../helpers/get-locale-value";
import Img from "gatsby-image";

import BlockDecoImageController from "../block-deco-image/block-deco-image-controller";
import Image from "../../image";

const useStyles = makeStyles({
  wrapper: {
    position: "relative",
    transform: props => props.transform,
    background: props => props.wrapperBg,
  },
  textWrapper: {
    position: "relative",
    color: "#595758",
    textAlign: "center",
    zIndex: 1,
  },
});

const ContentBlockVideoWithDecoPostDesktop = ({
  locale,
  data,
  padding_top,
  padding_left,
  padding_bottom,
  padding_right,
  margin_top,
  margin_left,
  margin_bottom,
  margin_right,
  z_index,
  translateX,
  translateY,
  translateZ,
}) => {
  const { video_variants, background, deco_image_block, divider } = data;

  const classes = useStyles({
    transform: `translateX(${translateX}) translateY(${translateY}) translateZ(${translateZ})`,
    wrapperBg: background,
  });

  const videoURL =
    process.env.GATSBY_ENV == "development" ? "http://localhost:1337" : "";

  return (
    <Box
      width="100%"
      pt={padding_top}
      pl={padding_left}
      pb={padding_bottom}
      pr={padding_right}
      mt={margin_top}
      ml={margin_left}
      mb={margin_bottom}
      mr={margin_right}
      zIndex={z_index}
      className={classes.wrapper}
    >
      {!reverse && (
        <Box position="relative">
          <video
            controls
            // loop
            // autoPlay={`autoplay`}
            muted
            playsInline
          >
            <source
              src={
                getLocaleValue(locale, video_variants).video.url.startsWith("/")
                  ? `${
                      process.env.GATSBY_ENV == "development"
                        ? "http://localhost:1337"
                        : ""
                    }${getLocaleValue(locale, video_variants).video.url}`
                  : `${process.env.GATSBY_CDN_IMAGE_URL}${
                      getLocaleValue(locale, video_variants).video.hash
                    }${getLocaleValue(locale, video_variants).video.ext}`
              }
              type={`${getLocaleValue(locale, video_variants).video.mime}`}
            />
            Your browser does not support the video tag.
          </video>

          {/* For image decoration overlay */}
          <BlockDecoImageController locale={locale} data={deco_image_block} />
        </Box>
      )}

      <Box className={classes.textWrapper}>
        {getLocaleValue(locale, video_variants).title && (
          <Box width="100%">
            {Parser(getLocaleValue(locale, video_variants).title)}
          </Box>
        )}

        {getLocaleValue(locale, video_variants).description && (
          <Box width="100%">
            {Parser(getLocaleValue(locale, video_variants).description)}
          </Box>
        )}
      </Box>

      {reverse && (
        <Box position="relative">
          <video
            controls
            // loop
            // autoPlay={`autoplay`}
            muted
            playsInline
          >
            <source
              src={
                getLocaleValue(locale, video_variants).video.url.startsWith("/")
                  ? `${
                      process.env.GATSBY_ENV == "development"
                        ? "http://localhost:1337"
                        : ""
                    }${getLocaleValue(locale, video_variants).video.url}`
                  : `${process.env.GATSBY_CDN_IMAGE_URL}${
                      getLocaleValue(locale, video_variants).video.hash
                    }${getLocaleValue(locale, video_variants).video.ext}`
              }
              type={`${getLocaleValue(locale, video_variants).video.mime}`}
            />
            Your browser does not support the video tag.
          </video>

          {/* For image decoration overlay */}
          <BlockDecoImageController locale={locale} data={deco_image_block} />
        </Box>
      )}
      {divider && (
        <Box display="flex" justifyContent="center">
          <Image loading="eager" imageObject={divider.backgroundImage} />
        </Box>
      )}
    </Box>
  );
};

export default ContentBlockVideoWithDecoPostDesktop;
