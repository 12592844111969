import React from "react";

import ResponsiveWrapper from "../responsive-wrapper";

import DynamicZoneDesktop from "./dynamic-zone-view-desktop";
import DynamicZoneTablet from "./dynamic-zone-view-tablet";
import DynamicZonePhone from "./dynamic-zone-view-phone";

const DynamicZoneController = ({ containers }) => {
  return (
    <ResponsiveWrapper
      DesktopComponent={<DynamicZoneDesktop containers={containers} />}
      TabletComponent={<DynamicZoneTablet containers={containers} />}
      PhoneComponent={<DynamicZonePhone containers={containers} />}
    />
  );
};

export default DynamicZoneController;
