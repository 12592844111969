import React from "react";

import BackdropImageViewDesktop from "./backdrop-image-view-desktop";
import BackdropImageViewTablet from "./backdrop-image-view-tablet";
import BackdropImageViewPhone from "./backdrop-image-view-phone";

import ResponsiveWrapper from "../responsive-wrapper";

const BackdropImageController = ({
  margin,
  padding,
  zIndex,
  image,
  downloadChannels,
}) => {
  return (
    <ResponsiveWrapper
      DesktopComponent={
        <BackdropImageViewDesktop
          image={image}
          margin={margin}
          padding={padding}
          zIndex={zIndex}
          downloadChannels={downloadChannels}
        />
      }
      TabletComponent={
        <BackdropImageViewTablet
          image={image}
          margin={margin}
          padding={padding}
          zIndex={zIndex}
          downloadChannels={downloadChannels}
        />
      }
      PhoneComponent={
        <BackdropImageViewPhone
          image={image}
          margin={margin}
          padding={padding}
          zIndex={zIndex}
          downloadChannels={downloadChannels}
        />
      }
    />
  );
};

export default BackdropImageController;
