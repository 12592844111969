import React from "react";
import { Box, Grid } from "@material-ui/core";
import Flickity from "react-flickity-component";

import PinnedCardsItemController from "./pinned-cards-item-controller";

import "flickity/css/flickity.css";

const PinnedCardsViewPhone = ({
  locale,
  activatedCards,
  expiredCards,
  margin,
  padding,
  zIndex,
}) => {
  const { padding_top, padding_bottom, padding_left, padding_right } = padding;
  const { margin_top, margin_bottom, margin_left, margin_right } = margin;

  return (
    <Box
      pt={padding_top}
      pb={padding_bottom}
      pl={padding_left}
      pr={padding_right}
      mt={margin_top}
      mb={margin_bottom}
      ml={margin_left}
      mr={margin_right}
      zIndex={zIndex}
    >
      <Grid container>
        <Grid item xs={12}>
          <Flickity
            className={`carousel gallery`} // default ''
            elementType={"div"} // default 'div'
            options={{
              wrapAround: false,
              pageDots: true,
              initialIndex: 0,
              prevNextButtons: false,
              contain: true,
            }} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false
            static // default false
          >
            {activatedCards?.map((c, i) => (
              <Box className="gallery-cell-full" key={i} px={1}>
                <PinnedCardsItemController data={c} />
              </Box>
            ))}
          </Flickity>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PinnedCardsViewPhone;
