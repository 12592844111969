import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Img from "gatsby-image";
import { Box } from "@material-ui/core";
import Image from "../image";

const useStyles = makeStyles({
  button: {
    width: "50px",
    marginRight: "10px",
  },
  buttonImage: {
    marginBottom: 0,
    maxWidth: "50px",
  },
});

const DownloadChannelsView = ({ locale, data }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="flex-end"
      justifyContent="center"
      p={5}
      pb={10}
      width={`100%`}
      height={`100%`}
      zIndex={2}
      position="relative"
    >
      {Array.from({ length: data.length }, (_, i) => {
        var item = data[i];

        return (
          item.name !== "download-nox" &&
          item.name !== "download-ld" && (
            <a
              href={item.download_url}
              key={i}
              className={classes.button}
              target="_blank"
            >
              <Image
                imageObject={item.download_icon_mobile}
                className={classes.buttonImage}
              />
            </a>
          )
        );
      })}
    </Box>
  );
};

export default DownloadChannelsView;
