import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Box } from "@material-ui/core";
import DownloadChannelController from "../download-channels/download-channels-controller";
import Video from "../video/Video";

const BackdropVideoViewDesktop = ({
  media,
  margin,
  padding,
  zIndex,
  downloadChannels,
}) => {
  const useStyles = makeStyles({
    backdropVideoWrapper: {
      position: "relative",
      zIndex: 0,
      height: 0,
      minHeight: "700px",
      overflow: "hidden",
      width: "100%",
    },
    backdropVideo: {
      width: "100%",
      height: "100%",
      position: "absolute",
      objectFit: "cover",
      top: 0,
    },
  });

  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      className={classes.backdropVideoWrapper}
    >
      <DownloadChannelController data={downloadChannels} />

      <video
        className={classes.backdropVideo}
        loop
        autoPlay={`autoplay`}
        muted
        playsInline
      >
        {/* <source src={`${videoURL}${media.url}`} type={media.mime} /> */}
        <source
          src={
            media.url.startsWith("/")
              ? `${
                  process.env.GATSBY_ENV == "development"
                    ? "http://localhost:1337"
                    : ""
                }${media.url}`
              : `${process.env.GATSBY_CDN_IMAGE_URL}${media.hash}${media.ext}`
          }
          type={`${media.mime}`}
        />
        Your browser does not support the video tag.
      </video>
    </Box>
  );
};

export default BackdropVideoViewDesktop;
