import React from "react";

import ResponsiveWrapper from "../../responsive-wrapper";

import BlockVideoPostDesktop from "./block-video-with-deco-post-view-desktop";
import BlockVideoPostTablet from "./block-video-with-deco-post-view-tablet";
import BlockVideoPostPhone from "./block-video-with-deco-post-view-phone";

const BlockVideoWithDecoPostController = ({
  data,
  locale,
  padding_top,
  padding_left,
  padding_bottom,
  padding_right,
  margin_top,
  margin_left,
  margin_bottom,
  margin_right,
  z_index,
  translateX,
  translateY,
  translateZ,
}) => {
  return (
    <ResponsiveWrapper
      DesktopComponent={
        <BlockVideoPostDesktop
          data={data}
          locale={locale}
          padding_top={padding_top}
          padding_left={padding_left}
          padding_bottom={padding_bottom}
          padding_right={padding_right}
          margin_top={margin_top}
          margin_left={margin_left}
          margin_bottom={margin_bottom}
          margin_right={margin_right}
          z_index={z_index}
          translateX={translateX}
          translateY={translateY}
          translateZ={translateZ}
        />
      }
      TabletComponent={
        <BlockVideoPostTablet
          data={data}
          locale={locale}
          padding_top={padding_top}
          padding_left={padding_left}
          padding_bottom={padding_bottom}
          padding_right={padding_right}
          margin_top={margin_top}
          margin_left={margin_left}
          margin_bottom={margin_bottom}
          margin_right={margin_right}
          z_index={z_index}
          translateX={translateX}
          translateY={translateY}
          translateZ={translateZ}
        />
      }
      PhoneComponent={
        <BlockVideoPostPhone
          data={data}
          locale={locale}
          padding_top={padding_top}
          padding_left={padding_left}
          padding_bottom={padding_bottom}
          padding_right={padding_right}
          margin_top={margin_top}
          margin_left={margin_left}
          margin_bottom={margin_bottom}
          margin_right={margin_right}
          z_index={z_index}
          translateX={translateX}
          translateY={translateY}
          translateZ={translateZ}
        />
      }
    />
  );
};

export default BlockVideoWithDecoPostController;
