import React from "react";

import Img from "gatsby-image";
import { Box, Typography } from "@material-ui/core";

import { getLocaleValue } from "../../../helpers/get-locale-value";
import { Card, CardMedia } from "@material-ui/core";
import { extractImagePathUrl } from "../../shared-components/image/image-url-helper";
import Navigation from "../../shared-components/navigation";

const NewsListItemView = ({ locale, data }) => {
  const { pinned_card, id, slug } = data;
  const pinnedCard = getLocaleValue(locale, pinned_card.card_variants);
  const { background_image, title } = pinnedCard;

  return (
    <Navigation to={`/news/${slug ? slug : id}`}>
      <Box display="flex" flexDirection="column">
        <Card className={`media-wrapper`}>
          <CardMedia
            className={`media`}
            image={extractImagePathUrl(background_image)}
          />
        </Card>

        <Box mt={2}>
          <h4>{title}</h4>
        </Box>
      </Box>
    </Navigation>
  );
};
export default NewsListItemView;
