import React, { useEffect, useState } from "react";
// import { graphql, useStaticQuery } from "gatsby";
import _ from "lodash";

import { useSelector } from "react-redux";

import moment from "moment";

import PinnedCardsViewDesktop from "./pinned-cards-view-desktop";
import PinnedCardsViewTablet from "./pinned-cards-view-tablet";
import PinnedCardsViewPhone from "./pinned-cards-view-phone";
import ResponsiveWrapper from "../shared-components/responsive-wrapper";

/**
     * {
        "id":1,
        "name":"First Card",
        "announced_at":"2021-04-16T04:00:00.000Z",
        "isArchived":null,
        "published_at":"2021-04-16T06:24:58.000Z",
        "created_at":"2021-04-16T06:23:51.000Z",
        "updated_at":"2021-04-16T06:24:58.000Z",
        "expired_at":"2021-04-20T04:00:00.000Z",
        "variants":[
          {
            "id":1,
            "name":"first-card-en",
            "title":"First Card",
            "path":"/first-card",
            "redirect":null,
            "locale":{
              "id":1,
              "name":"English",
              "code":"en-US",
              "native_name":"English (United States)",
              "presentation_name":"English",
              "rtl":false,
              "default":true,
              "display_code":"en",
              "forum_category_id":null,
              "published_at":"2021-02-23T07:06:57.000Z",
              "created_at":"2021-02-23T07:06:57.000Z",
              "updated_at":"2021-02-23T07:06:57.000Z"
            },
            "cardImage":{
              # image object
            }
        ]
      }
     */

const PinnedCardsController = ({
  pinnedCards,
  margin,
  padding,
  zIndex,
  filter,
  anchorLink,
  numberPerPage,
}) => {
  const [availableCards, setAvailableCards] = useState([]);
  const [expiredCards, setExpiredCards] = useState([]);

  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  useEffect(() => {
    const activatedPinnedCard = _.filter(pinnedCards, pinnedCard => {
      const { expired_at } = pinnedCard;

      if (expired_at) {
        if (moment.utc().isBefore(expired_at)) {
          return true;
        }
        return false;
      }
      // else : expired_at is null
      return true;
    });

    const availablePinnedCard = _.filter(activatedPinnedCard, pinnedCard => {
      const { announced_at } = pinnedCard;

      if (announced_at) {
        if (moment.utc().isAfter(announced_at)) {
          return true;
        }
        return false;
      }
      // else : announced_at is null
      return true;
    });

    const expiredPinnedCard = _.filter(pinnedCards, pinnedCard => {
      if (pinnedCard.expired_at) {
        if (moment.utc().isBefore(pinnedCard.expired_at)) {
          return false;
        }
        return true;
      }
      // else : expired_at is null
      return false;
    });

    const sortedAvailablePinnedCard = _.orderBy(
      availablePinnedCard,
      [
        pinnedCard => {
          return pinnedCard.announced_at;
        },
        pinnedCard => {
          return pinnedCard.created_at;
        },
      ],
      ["desc", "desc"]
    );

    const sortedExpiredPinnedCard = _.orderBy(
      expiredPinnedCard,
      [
        pinnedCard => {
          return pinnedCard.announced_at;
        },
        pinnedCard => {
          return pinnedCard.created_at;
        },
      ],
      ["desc", "desc"]
    );

    setAvailableCards(sortedAvailablePinnedCard);
    setExpiredCards(sortedExpiredPinnedCard);
  }, [pinnedCards]);

  return (
    <ResponsiveWrapper
      DesktopComponent={
        <PinnedCardsViewDesktop
          locale={locale}
          activatedCards={availableCards}
          expiredCards={expiredCards}
          margin={margin}
          padding={padding}
          zIndex={zIndex}
        />
      }
      TabletComponent={
        <PinnedCardsViewTablet
          locale={locale}
          activatedCards={availableCards}
          expiredCards={expiredCards}
          margin={margin}
          padding={padding}
          zIndex={zIndex}
        />
      }
      PhoneComponent={
        <PinnedCardsViewPhone
          locale={locale}
          activatedCards={availableCards}
          expiredCards={expiredCards}
          margin={margin}
          padding={padding}
          zIndex={zIndex}
        />
      }
    />
  );
};

export default PinnedCardsController;
