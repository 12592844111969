import React from "react";
import Parser from "html-react-parser";
import { useSelector } from "react-redux";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import { makeStyles } from "@material-ui/core/styles";
import { Card, CardMedia, Box, Grid, Typography } from "@material-ui/core";

import { MdExpandMore } from "@react-icons/all-files/md/MdExpandMore";

import { BsStarFill } from "@react-icons/all-files/bs/BsStarFill";
import { BsBellFill } from "@react-icons/all-files/bs/BsBellFill";
import { BsCloudFill } from "@react-icons/all-files/bs/BsCloudFill";

import { getLocaleValue } from "../../../helpers/get-locale-value";
import { getLocaleValueFromArrayByName } from "../../../helpers/get-locale-value-from-array-by-name";

import { extractImagePathUrl } from "../../shared-components/image/image-url-helper";
import Navigation from "../../shared-components/navigation";

const useStyles = makeStyles({
  accordion: {
    background: "transparent",
    boxShadow: "none",
  },

  accordionDetails: {
    background: "transparent",
  },

  accordionSummary: {
    background: "transparent",
    borderTop: 1,
    borderStyle: "solid",
    borderTopColor: "#FF8923",
    flexDirection: "row-reverse",
    minHeight: 60,
    paddingRight: 0,
  },
  icon: {
    background: "#868686",
    borderRadius: "50%",
    color: "#fff",
    marginRight: 0,
  },
  readMoreLink: {
    color: "#189CD9",
  },
  categoryTag: {
    fontWeight: "bold",
    background: props => props.news_category.background_color,
  },
});

const NewsListItemView = ({
  index,
  locale,
  data,
  handleChange,
  expandedPanel,
}) => {
  const { slug, id, variants, news_category } = data;

  const newsPost = getLocaleValue(locale, variants);
  const category = getLocaleValue(locale, news_category.variants);

  const copywriting_variants = useSelector(state => {
    return state.siteSettingReducer.copywriting_variants;
  }, []);

  // Rendering icons based on cms settings, icons are currently hard coded
  const renderIcons = () => {
    switch (news_category.font_icon) {
      case "bs/BsStarFill":
        return (
          <BsStarFill
            color={news_category.font_icon_color}
            className={`accordion-category-title-icon`}
          />
        );
        break;
      case "bs/BsBellFill":
        return (
          <BsBellFill
            color={news_category.font_icon_color}
            className={`accordion-category-title-icon`}
          />
        );
        break;
      case "bs/BsCloudFill":
        return (
          <BsCloudFill
            color={news_category.font_icon_color}
            className={`accordion-category-title-icon`}
          />
        );
        break;
    }
  };

  const classes = useStyles({
    news_category: news_category,
  });

  return (
    <Accordion
      className={classes.accordion}
      defaultExpanded={index == 0}
      onChange={handleChange("panel" + index)}
      expanded={expandedPanel == "panel" + index}
    >
      <AccordionSummary
        classes={{ root: "accordion-right-icon accordion-expand-hide-title" }}
        className={classes.accordionSummary}
        expandIcon={<MdExpandMore className={classes.icon} />}
        id={`newsAccordion` + newsPost.id}
      >
        <Box className={`accordion-hide-title`}>
          <Typography variant="h6">{newsPost.title}</Typography>
        </Box>

        <Box
          display="flex"
          className={`accordion-category-title ${classes.categoryTag}`}
        >
          {renderIcons()}

          <Box>{category.content}</Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.accordion}>
        <Box mb={2} width="100%">
          <Grid container spacing={3}>
            <Grid container item xs={3}>
              {newsPost.banner && (
                <Card className={`media-wrapper`}>
                  <CardMedia
                    className={`media`}
                    image={extractImagePathUrl(newsPost.banner)}
                  />
                </Card>
              )}
            </Grid>
            <Grid container item xs={8}>
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
              >
                <Box width="100%">
                  <Box width="100%" fontWeight="bold" mb={2}>
                    <Typography variant="h6">{newsPost.title}</Typography>
                  </Box>
                  <Box width="100%">{Parser(newsPost.html_summary)}</Box>
                </Box>

                <Typography align="right">
                  <Navigation
                    to={`/news/${slug ? slug : id}`}
                    className={classes.readMoreLink}
                  >
                    {copywriting_variants &&
                      getLocaleValueFromArrayByName(
                        locale,
                        copywriting_variants,
                        "copywriting-readmore"
                      ).content}
                  </Navigation>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
export default NewsListItemView;
