import React from "react";
import { useSelector } from "react-redux";

import SocialMediaDesktop from "./social-media-view-desktop";
import SocialMediaTablet from "./social-media-view-tablet";

import ResponsiveWrapper from "../../shared-components/responsive-wrapper";

const SocialMediaController = ({ data }) => {
  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  return (
    <ResponsiveWrapper
      DesktopComponent={<SocialMediaDesktop locale={locale} data={data} />}
      TabletComponent={<SocialMediaTablet locale={locale} data={data} />}
    />
  );
};
export default SocialMediaController;
