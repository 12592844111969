import React from "react";
import { useSelector } from "react-redux";

import DownloadChannelsDesktop from "./download-channels-view-desktop";
import DownloadChannelsTablet from "./download-channels-view-tablet";
import DownloadChannelsPhone from "./download-channels-view-phone";

import ResponsiveWrapper from "../responsive-wrapper";

const DownloadChannelsController = ({ data }) => {
  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  return (
    <ResponsiveWrapper
      DesktopComponent={<DownloadChannelsDesktop locale={locale} data={data} />}
      TabletComponent={<DownloadChannelsTablet locale={locale} data={data} />}
      PhoneComponent={<DownloadChannelsPhone locale={locale} data={data} />}
    />
  );
};

export default DownloadChannelsController;
