import React from "react";
import { Box, Typography } from "@material-ui/core";

import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FiYoutube } from "@react-icons/all-files/fi/FiYoutube";

import { makeStyles } from "@material-ui/core/styles";

import { getLocaleValue } from "../../../helpers/get-locale-value";

const socialMediaView = ({ locale, data }) => {
  // Rendering icons based on cms settings, icons are currently hard coded
  const renderIcons = data => {
    switch (data.font_icon) {
      case "fa/FaFacebookF":
        return <FaFacebookF />;
      case "fa/FaInstagram":
        return <FaInstagram />;
      case "fi/FiYoutube":
        return <FiYoutube />;
    }
  };

  const useStyles = makeStyles({
    socialMedia: {
      position: "absolute",
      zIndex: 9,
      maxWidth: "340px",
      fontSize: "0.8em",
      top: "530px",
      right: "30px",
    },
    socialMediaIcons: {
      position: "relative",
      marginLeft: "-20%",
      marginBottom: "10%",
    },
  });

  const classess = useStyles();

  return (
    <Box display="flex" className={classess.socialMedia}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        className={classess.socialMediaIcons}
      >
        {Array.from({ length: data.length }, (_, i) => {
          var socialMedia = data[i];

          const useStylesSocialMedia = makeStyles({
            socialMediaIcon: {
              background: socialMedia.font_icon_background_color,
              color: socialMedia.font_icon_color,
              fontSize: "2em",
              borderRadius: "50%",
              boxShadow: "8px 8px 8px rgba(100,100,100,0.8)",
            },
          });

          const socialMediaClasses = useStylesSocialMedia();

          return (
            <a
              key={i}
              href={getLocaleValue(locale, socialMedia.url_variants).content}
              target="_blank"
            >
              <Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width={40}
                  height={40}
                  m={0.8}
                  className={socialMediaClasses.socialMediaIcon}
                >
                  {renderIcons(socialMedia)}
                </Box>
              </Box>
            </a>
          );
        })}
      </Box>
    </Box>
  );
};

export default socialMediaView;
