import React from "react";
// import { graphql, useStaticQuery } from "gatsby";
import _ from "lodash";

import { useSelector } from "react-redux";

import NewsCardsViewDesktop from "./news-cards-view-desktop";
import NewsCardsViewTablet from "./news-cards-view-tablet";
import NewsCardsViewPhone from "./news-cards-view-phone";
import ResponsiveWrapper from "../../shared-components/responsive-wrapper";

const NewsCardsController = ({
  newsPosts,
  margin,
  padding,
  zIndex,
  filter,
  anchorLink,
  numberPerPage,
}) => {
  // const data = useStaticQuery(graphql`
  //   query news_cards {
  //     allStrapiNewsPost(
  //       sort: { order: DESC, fields: announced_at }
  //       filter: { pinned_card: { pinned: { eq: true } } }
  //     ) {
  //       nodes {
  //         id: strapiId
  //         name
  //         slug
  //         news_category {
  //           name
  //           variants {
  //             content
  //             locale {
  //               code
  //             }
  //           }
  //         }
  //         variants {
  //           locale {
  //             code
  //           }
  //           id
  //           title
  //           html_summary
  //           html_full_content
  //           forum_url
  //           forum_thread_id
  //           banner {
  //             childImageSharp {
  //               fluid(
  //                 maxWidth: 600
  //                 srcSetBreakpoints: [600, 1920]
  //                 quality: 80
  //               ) {
  //                 #...GatsbyImageSharpFluid_noBase64
  //                 ...GatsbyImageSharpFluid_withWebp_noBase64
  //               }
  //             }
  //           }
  //         }
  //         pinned_card {
  //           pinned
  //           name
  //           card_variants {
  //             title
  //             background_image {
  //               childImageSharp {
  //                 fluid(
  //                   maxWidth: 600
  //                   srcSetBreakpoints: [600, 1920]
  //                   quality: 80
  //                 ) {
  //                   ...GatsbyImageSharpFluid_withWebp_noBase64
  //                 }
  //               }
  //             }
  //             name
  //             locale {
  //               code
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);

  const filteredNews = _.filter(newsPosts, newsPost => {
    const { pinned_card } = newsPost;
    if (!pinned_card) {
      return false;
    }

    if (!pinned_card.pinned) {
      return false;
    }

    return true;
  });

  const sortedNews = _.orderBy(
    filteredNews,
    [
      newsPost => {
        return newsPost.announced_at;
      },
      newsPost => {
        return newsPost.created_at;
      },
    ],
    ["desc", "desc"]
  );

  // const { nodes } = data.allStrapiNewsPost;

  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  return (
    <ResponsiveWrapper
      DesktopComponent={
        <NewsCardsViewDesktop
          locale={locale}
          cards={sortedNews}
          margin={margin}
          padding={padding}
          zIndex={zIndex}
        />
      }
      TabletComponent={
        <NewsCardsViewTablet
          locale={locale}
          cards={sortedNews}
          margin={margin}
          padding={padding}
          zIndex={zIndex}
        />
      }
      PhoneComponent={
        <NewsCardsViewPhone
          locale={locale}
          cards={sortedNews}
          margin={margin}
          padding={padding}
          zIndex={zIndex}
        />
      }
    />
  );
};

export default NewsCardsController;
