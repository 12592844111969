import React from "react";

import BackdropDecorationViewDesktop from "./backdrop-decoration-view-desktop";
import BackdropDecorationViewTablet from "./backdrop-decoration-view-tablet";
import BackdropDecorationViewPhone from "./backdrop-decoration-view-phone";

import ResponsiveWrapper from "../responsive-wrapper";

const BackdropImageController = ({ margin, padding, zIndex, image }) => {
  return (
    <ResponsiveWrapper
      DesktopComponent={
        <BackdropDecorationViewDesktop
          image={image}
          margin={margin}
          padding={padding}
          zIndex={zIndex}
        />
      }
      TabletComponent={
        <BackdropDecorationViewTablet
          image={image}
          margin={margin}
          padding={padding}
          zIndex={zIndex}
        />
      }
      PhoneComponent={
        <BackdropDecorationViewPhone
          image={image}
          margin={margin}
          padding={padding}
          zIndex={zIndex}
        />
      }
    />
  );
};

export default BackdropImageController;
