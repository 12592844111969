import { Box } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";

import BlockCharacterController from "../blocks/block-character/block-character-controller";
import BlockImageController from "../blocks/block-image/block-image-controller";
import BlockVideoController from "../blocks/block-video/block-video-controller";
import BlockImagePostController from "../blocks/block-image-post/block-image-post-controller";
import BlockVideoPostController from "../blocks/block-video-post/block-video-post-controller";
import BlockTabsVideoPostController from "../blocks/block-tabs-video-post/block-tabs-video-post-controller";
import BlockVideoWithDecoPostController from "../blocks/block-video-with-deco-post/block-video-with-deco-post-controller";

const DynamicBlockSwitcher = ({ data }) => {
  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  const padding_top = data.spatial
    ? data.spatial.padding
      ? data.spatial.padding.padding_top
      : "0px"
    : "0px";
  const padding_left = data.spatial
    ? data.spatial.padding
      ? data.spatial.padding.padding_left
      : "0px"
    : "0px";
  const padding_bottom = data.spatial
    ? data.spatial.padding
      ? data.spatial.padding.padding_bottom
      : "0px"
    : "0px";
  const padding_right = data.spatial
    ? data.spatial.padding
      ? data.spatial.padding.padding_right
      : "0px"
    : "0px";

  const margin_top = data.spatial
    ? data.spatial.margin
      ? data.spatial.margin.margin_top
      : "0px"
    : "0px";
  const margin_left = data.spatial
    ? data.spatial.margin
      ? data.spatial.margin.margin_left
      : "0px"
    : "0px";
  const margin_bottom = data.spatial
    ? data.spatial.margin
      ? data.spatial.margin.margin_bottom
      : "0px"
    : "0px";
  const margin_right = data.spatial
    ? data.spatial.margin
      ? data.spatial.margin.margin_right
      : "0px"
    : "0px";

  const z_index = data.spatial ? data.spatial.z_index : "0px";
  const translateX =
    data.spatial && data.spatial.translate ? data.spatial.translate.x : "0px";
  const translateY =
    data.spatial && data.spatial.translate ? data.spatial.translate.y : "0px";
  const translateZ =
    data.spatial && data.spatial.translate ? data.spatial.translate.z : "0px";

  switch (data.type) {
    case "block_image":
      return (
        <BlockImageController
          data={data}
          locale={locale}
          padding_top={padding_top}
          padding_left={padding_left}
          padding_bottom={padding_bottom}
          padding_right={padding_right}
          margin_top={margin_top}
          margin_left={margin_left}
          margin_bottom={margin_bottom}
          margin_right={margin_right}
          z_index={z_index}
          translateX={translateX}
          translateY={translateY}
          translateZ={translateZ}
        />
      );
    case "block_video":
      return (
        <BlockVideoController
          data={data}
          locale={locale}
          padding_top={padding_top}
          padding_left={padding_left}
          padding_bottom={padding_bottom}
          padding_right={padding_right}
          margin_top={margin_top}
          margin_left={margin_left}
          margin_bottom={margin_bottom}
          margin_right={margin_right}
          z_index={z_index}
          translateX={translateX}
          translateY={translateY}
          translateZ={translateZ}
        />
      );
    case "block_image_post":
      return (
        <BlockImagePostController
          data={data}
          locale={locale}
          padding_top={padding_top}
          padding_left={padding_left}
          padding_bottom={padding_bottom}
          padding_right={padding_right}
          margin_top={margin_top}
          margin_left={margin_left}
          margin_bottom={margin_bottom}
          margin_right={margin_right}
          z_index={z_index}
          translateX={translateX}
          translateY={translateY}
          translateZ={translateZ}
        />
      );
    case "block_video_post":
      return (
        <BlockVideoPostController
          data={data}
          locale={locale}
          padding_top={padding_top}
          padding_left={padding_left}
          padding_bottom={padding_bottom}
          padding_right={padding_right}
          margin_top={margin_top}
          margin_left={margin_left}
          margin_bottom={margin_bottom}
          margin_right={margin_right}
          z_index={z_index}
          translateX={translateX}
          translateY={translateY}
          translateZ={translateZ}
        />
      );
    case "block_character":
      return (
        <BlockCharacterController
          data={data}
          locale={locale}
          padding_top={padding_top}
          padding_left={padding_left}
          padding_bottom={padding_bottom}
          padding_right={padding_right}
          margin_top={margin_top}
          margin_left={margin_left}
          margin_bottom={margin_bottom}
          margin_right={margin_right}
          z_index={z_index}
          translateX={translateX}
          translateY={translateY}
          translateZ={translateZ}
        />
      );
    case "block_tabs_video_post":
      return (
        <BlockTabsVideoPostController
          data={data}
          locale={locale}
          padding_top={padding_top}
          padding_left={padding_left}
          padding_bottom={padding_bottom}
          padding_right={padding_right}
          margin_top={margin_top}
          margin_left={margin_left}
          margin_bottom={margin_bottom}
          margin_right={margin_right}
          z_index={z_index}
          translateX={translateX}
          translateY={translateY}
          translateZ={translateZ}
        />
      );
    case "block_video_with_deco_post":
      return (
        <BlockVideoWithDecoPostController
          data={data}
          locale={locale}
          padding_top={padding_top}
          padding_left={padding_left}
          padding_bottom={padding_bottom}
          padding_right={padding_right}
          margin_top={margin_top}
          margin_left={margin_left}
          margin_bottom={margin_bottom}
          margin_right={margin_right}
          z_index={z_index}
          translateX={translateX}
          translateY={translateY}
          translateZ={translateZ}
        />
      );
    default:
      return <Box></Box>;
  }
};

export default DynamicBlockSwitcher;
