import React from "react";
import Navigation from "../shared-components/navigation";
import { Box } from "@material-ui/core";
import { Card, CardMedia } from "@material-ui/core";
import { getLocaleValue } from "../../helpers/get-locale-value";
import { extractImagePathUrl } from "../shared-components/image/image-url-helper";

const PinnedListItemView = ({ locale, data }) => {
  const { variants } = data;
  const pinnedCard = getLocaleValue(locale, variants);

  const { cardImage, title, sitemap } = pinnedCard;

  return (
    <Navigation to={sitemap.path} reloadRequired={sitemap.reload_required}>
      <Box display="flex" flexDirection="column">
        <Card className={`media-wrapper`}>
          <CardMedia
            className={`media`}
            image={extractImagePathUrl(cardImage)}
          />
        </Card>

        <Box mt={2}>
          <h4>{title}</h4>
        </Box>
      </Box>
    </Navigation>
  );
};
export default PinnedListItemView;
