import React from "react";

import BackdropDecorationLogoViewDesktop from "./backdrop-decoration-logo-view-desktop";
import BackdropDecorationLogoViewTablet from "./backdrop-decoration-logo-view-tablet";
import BackdropDecorationLogoViewPhone from "./backdrop-decoration-logo-view-phone";

import ResponsiveWrapper from "../responsive-wrapper";

const BackdropImageController = ({ margin, padding, zIndex, image }) => {
  return (
    <ResponsiveWrapper
      DesktopComponent={
        <BackdropDecorationLogoViewDesktop
          image={image}
          margin={margin}
          padding={padding}
          zIndex={zIndex}
        />
      }
      TabletComponent={
        <BackdropDecorationLogoViewTablet
          image={image}
          margin={margin}
          padding={padding}
          zIndex={zIndex}
        />
      }
      PhoneComponent={
        <BackdropDecorationLogoViewPhone
          image={image}
          margin={margin}
          padding={padding}
          zIndex={zIndex}
        />
      }
    />
  );
};

export default BackdropImageController;
