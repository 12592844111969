import React from "react";
import { useSelector } from "react-redux";

import NewsCardsItemView from "./news-cards-item-view";

import ResponsiveWrapper from "../../shared-components/responsive-wrapper";

const NewsCardsItemController = ({ data }) => {
  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  return (
    <ResponsiveWrapper
      DesktopComponent={<NewsCardsItemView locale={locale} data={data} />}
      TabletComponent={<NewsCardsItemView locale={locale} data={data} />}
      PhoneComponent={<NewsCardsItemView locale={locale} data={data} />}
    />
  );
};
export default NewsCardsItemController;
