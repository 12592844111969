import React from "react";
// import useAxios from "axios-hooks";

import { Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import DynamicZoneController from "../dynamic-zone/dynamic-zone-controller";
import { extractImagePathUrl } from "../image/image-url-helper";

const useStyles = makeStyles({
  gridWrapper: {
    paddingTop: "850px",
    minHeight: "100vh",
    backgroundImage: props => props.backDropImage,
    backgroundSize: "cover",
    backgroundPosition: "top center",

    "@media screen and (max-width: 1600px)": {
      paddingTop: "600px",
    },
    "@media screen and (max-width: 1280px)": {
      paddingTop: "530px",
    },
    "@media screen and (max-width: 900px)": {
      paddingTop: "400px",
    },
    "@media screen and (max-width: 768px)": {
      paddingTop: "350px",
    },
  },
});

const BackdrapImageContainerController = ({ image, blockContainers }) => {
  // const [{ data: nodes, loading, error }, refetch] = useAxios(
  //   {
  //     method: "GET",
  //     url: "/block-containers",
  //     baseURL: process.env.GATSBY_STRAPI_API_LAN_URL || "http://localhost:1337",
  //   },
  //   {
  //     manual: true,
  //     ssr: true,
  //   }
  // );

  // const [{ data: news, loading: loadingnews, error: errornews }] = useAxios(
  //   {
  //     method: "GET",
  //     url: "/news-posts",
  //     baseURL: process.env.GATSBY_STRAPI_API_LAN_URL || "http://localhost:1337",
  //   },
  //   {
  //     manual: true,
  //     ssr: true,
  //   }
  // );

  const classes = useStyles({
    backDropImage: `url(${extractImagePathUrl(image)})`,
  });

  return (
    <Box className={classes.gridWrapper}>
      <Box px={{ xs: 2, sm: 4, lg: 8 }}>
        <DynamicZoneController containers={blockContainers} />
      </Box>
    </Box>
  );
};

export default BackdrapImageContainerController;
