import React, { useEffect, useCallback, useState } from "react";
import { graphql } from "gatsby";
import moment from "moment";

import { useSelector, useDispatch } from "react-redux";

import { storeCopywritingValue } from "../modules/copywriting/action";

import { Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Header from "../modules/shared-components/header/header-controller";
import Footer from "../modules/shared-components/footer/footer-controller";

import BackdrapImageContainerController from "../modules/shared-components/backdrop-image-container/backdrop-image-container-controller";
import BackdropVideoController from "../modules/shared-components/backdrop/backdrop-video-controller";
import BackdropImageController from "../modules/shared-components/backdrop/backdrop-image-controller";
import BackdropDecorationController from "../modules/shared-components/backdrop/backdrop-decoration-controller";
import BackdropDecorationLogoController from "../modules/shared-components/backdrop/backdrop-decoration-logo-controller";
import NewsBoardController from "../modules/news/board/news-board-controller";
import NewsCardsController from "../modules/news/cards/news-cards-controller";
import PinnedCardsController from "../modules/pinned-card/pinned-cards-controller";

import SocialMediaController from "../modules/shared-components/social-media/social-media-controller";

import homepage_background from "../images/homepage_background.png";
import _ from "lodash";
import SEO from "../modules/shared-components/seo";
import { storeSiteSettingValue } from "../modules/site-setting/action";
import { getLocaleValue } from "../helpers/get-locale-value";
import { getMetaDetails } from "../helpers/get-meta-details";

// export const query = graphql`
//   query index {
//     strapiSiteSetting {
//       copywriting_variants {
//         variants {
//           content
//           id
//           locale {
//             code
//           }
//         }
//         name
//       }
//       download_channels {
//         name
//         download_url
//         download_icon_variants {
//           locale {
//             code
//           }
//           media {
//             childImageSharp {
//               fluid(
//                 maxWidth: 150
//                 srcSetBreakpoints: [600, 1920]
//                 quality: 80
//               ) {
//                 ...GatsbyImageSharpFluid_withWebp_noBase64
//               }
//             }
//           }
//         }
//         download_icon_mobile {
//           childImageSharp {
//             fluid(maxWidth: 50, srcSetBreakpoints: [600, 1920], quality: 80) {
//               ...GatsbyImageSharpFluid_withWebp_noBase64
//             }
//           }
//         }
//       }
//       social_medias {
//         font_icon_color
//         font_icon_background_color
//         font_icon
//         display_analytics
//         id
//         name
//         url_variants {
//           content
//           locale {
//             code
//           }
//         }
//         mascot_image {
//           childImageSharp {
//             fluid(maxWidth: 200, srcSetBreakpoints: [1920], quality: 80) {
//               ...GatsbyImageSharpFluid_withWebp_noBase64
//             }
//           }
//         }
//         analytics {
//           id
//           name
//           value
//           label_variants {
//             content
//             locale {
//               code
//             }
//           }
//         }
//       }
//     }
//     strapiHomepage {
//       number_sections
//       name
//       section_1 {
//         anchor_link
//         margin {
//           margin_bottom
//           margin_left
//           margin_right
//           margin_top
//         }
//         padding {
//           padding_bottom
//           padding_left
//           padding_right
//           padding_top
//         }
//         name
//         section_type
//         z_index
//         media {
//           publicURL
//           extension
//         }
//       }
//       section_2 {
//         name
//         section_type
//         anchor_link
//         margin {
//           margin_bottom
//           margin_left
//           margin_right
//           margin_top
//         }
//         padding {
//           padding_bottom
//           padding_left
//           padding_right
//           padding_top
//         }
//         z_index
//         media {
//           extension
//           childImageSharp {
//             fluid(maxWidth: 1920, srcSetBreakpoints: [600, 1920], quality: 80) {
//               ...GatsbyImageSharpFluid_withWebp_noBase64
//             }
//           }
//         }
//       }
//       section_3 {
//         name
//         section_type
//         anchor_link
//         margin {
//           margin_bottom
//           margin_left
//           margin_right
//           margin_top
//         }
//         padding {
//           padding_bottom
//           padding_left
//           padding_right
//           padding_top
//         }
//         z_index
//         media {
//           extension
//           childImageSharp {
//             fluid(maxWidth: 1920, srcSetBreakpoints: [600, 1920], quality: 80) {
//               ...GatsbyImageSharpFluid_withWebp_noBase64
//             }
//           }
//         }
//       }
//       section_4 {
//         name
//         section_type
//         margin {
//           margin_bottom
//           margin_left
//           margin_right
//           margin_top
//         }
//         padding {
//           padding_bottom
//           padding_left
//           padding_right
//           padding_top
//         }
//         z_index
//         data_filter
//         data_source
//         display_title
//         number_per_page
//         presentation
//         anchor_link
//       }
//       section_5 {
//         name
//         section_type
//         margin {
//           margin_bottom
//           margin_left
//           margin_right
//           margin_top
//         }
//         padding {
//           padding_bottom
//           padding_left
//           padding_right
//           padding_top
//         }
//         z_index
//         data_filter
//         data_source
//         display_title
//         number_per_page
//         presentation
//         title_variants {
//           locale {
//             code
//           }
//           content
//         }
//         anchor_link
//       }
//       section_6 {
//         name
//         section_type
//         image {
//           childImageSharp {
//             fluid(maxWidth: 1920, srcSetBreakpoints: [600, 1920], quality: 80) {
//               ...GatsbyImageSharpFluid_withWebp_noBase64
//             }
//           }
//         }
//         block_containers {
//           name
//           type
//           id
//         }
//       }
//     }
//   }
// `;

const useStyles = makeStyles({
  gridWrapper: {
    minHeight: "100vh",
    // backgroundImage: props => props.backDropImage,
    backgroundImage: `url(${homepage_background})`,
    backgroundSize: "cover",
    backgroundPosition: "top center",
    paddingBottom: "30px",

    // "@media screen and (max-width: 1600px)": {
    //   paddingTop: "600px",
    // },
    // "@media screen and (max-width: 1280px)": {
    //   paddingTop: "530px",
    // },
    // "@media screen and (max-width: 900px)": {
    //   paddingTop: "400px",
    // },
    // "@media screen and (max-width: 768px)": {
    //   paddingTop: "350px",
    // },
  },
});

const IndexPage = ({ location, pageContext }) => {
  const {
    homepage,
    header,
    footer,
    pinnedCards,
    newsPosts,
    blockContainers,
    siteSetting,
    reloadRequired,
    meta_details_variants,
  } = pageContext;

  const { number_sections } = homepage;
  const { social_medias, download_channels } = siteSetting;

  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  const classes = useStyles();

  useEffect(() => {
    stableDispatch(storeCopywritingValue(siteSetting.copywriting_variants));
  }, [siteSetting.copywriting_variants, stableDispatch]);

  useEffect(() => {
    stableDispatch(storeSiteSettingValue(siteSetting));
  }, [siteSetting, stableDispatch]);

  const renderSections = i => {
    const section = homepage[`section_${i + 1}`];
    const { section_type, margin, padding, z_index } = section;

    switch (section_type) {
      case "backdrop_video":
        const { media: videoMedia } = section;
        return (
          <BackdropVideoController
            key={`backdropVideoController` + i}
            margin={margin}
            padding={padding}
            zIndex={z_index}
            media={videoMedia}
            downloadChannels={download_channels}
          />
        );
      case "backdrop_image":
        const { media: imageMedia } = section;
        return (
          <BackdropImageController
            key={`backdropImageController` + i}
            margin={margin}
            padding={padding}
            zIndex={z_index}
            image={imageMedia}
            downloadChannels={download_channels}
          />
        );
      case "backdrop_deco":
        const { media: decoImage } = section;
        return (
          <BackdropDecorationController
            key={`backdropDecorationController` + i}
            margin={margin}
            padding={padding}
            zIndex={z_index}
            image={decoImage}
          />
        );
      case "backdrop_deco_logo":
        const { media: decoLogo } = section;
        return (
          <BackdropDecorationLogoController
            key={`backdropDecorationLogoController` + i}
            margin={margin}
            padding={padding}
            zIndex={z_index}
            image={decoLogo}
          />
        );
      case "listing":
        const {
          presentation,
          anchor_link,
          data_filter,
          data_source,
          display_title,
          number_per_page,
        } = section;

        switch (presentation) {
          case "list_with_no_pagination":
            const { title_variants } = section;

            switch (data_source) {
              case "news-post":
                return (
                  <Container key={`newsBoardControllerWrapper` + i}>
                    <NewsBoardController
                      data={newsPosts}
                      margin={margin}
                      padding={padding}
                      zIndex={z_index}
                      filter={data_filter}
                      anchorLink={anchor_link}
                      displayTitle={display_title}
                      numberPerPage={number_per_page}
                      titleVariants={title_variants}
                    />
                  </Container>
                );
              default:
                return <div></div>;
            }

          case "cardslider":
            switch (data_source) {
              case "news-post":
                return (
                  <Container key={`cardslider-news-post` + i}>
                    <NewsCardsController
                      newsPosts={newsPosts}
                      margin={margin}
                      padding={padding}
                      zIndex={z_index}
                      filter={data_filter}
                      anchorLink={anchor_link}
                      displayTitle={display_title}
                      numberPerPage={number_per_page}
                    />
                  </Container>
                );
              case "pinned-card": {
                return (
                  <Container key={`cardslider-pinned-card` + i}>
                    <PinnedCardsController
                      pinnedCards={pinnedCards}
                      margin={margin}
                      padding={padding}
                      zIndex={z_index}
                      filter={data_filter}
                      anchorLink={anchor_link}
                      displayTitle={display_title}
                      numberPerPage={number_per_page}
                    />
                  </Container>
                );
              }

              default:
                return <div></div>;
            }
            return <div></div>;

          default:
            return <div></div>;
        }

      case "backdrop_image_container":
        const { image, block_containers } = section;

        const results = _.filter(blockContainers, container => {
          let isFound = false;

          _.forEach(block_containers, bc => {
            if (bc.id === container.id) {
              isFound = true;
            }
          });

          if (isFound) {
            return true;
          } else {
            return false;
          }
        });

        return (
          <BackdrapImageContainerController
            key={`BackdrapImageContainerController`}
            image={image}
            blockContainers={results}
          />
        );
      // case "backdrop_video_container":
      //   return <div />;
      default:
        return <Box></Box>;
    }
  };

  const seoData = getMetaDetails(
    locale,
    meta_details_variants,
    siteSetting,
    siteSetting
  );

  return (
    <>
      <SEO siteSetting={seoData} />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        minHeight="100vh"
      >
        <Header
          location={location}
          header={header}
          siteSetting={siteSetting}
          reloadRequired={reloadRequired}
        />

        <div
          style={{
            flex: 1,
          }}
        >
          <SocialMediaController data={social_medias} />

          {/* {Array.from({ length: number_sections }, (_, i) => {
            return renderSections(i);
          })} */}

          {/* section_1 */ renderSections(0)}
          {/* section_3 */ renderSections(2)}

          <Box className={classes.gridWrapper}>
            {/* section_2 */ renderSections(1)}
            {/* section_4 */ renderSections(3)}
            {/* section_5 */ renderSections(4)}
          </Box>
          {/* section_6 */ renderSections(5)}
        </div>
        <Footer location={location} footer={footer} siteSetting={siteSetting} />
      </Box>
    </>
  );
};

export default IndexPage;
