import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Parser from "html-react-parser";
import { Box } from "@material-ui/core";

import Img from "gatsby-image";
import { getLocaleValue } from "../../../../helpers/get-locale-value";
import Image from "../../image";

const useStyles = makeStyles({
  wrapper: {
    position: "relative",
    textAlign: "center",
    transform: props => props.transform,
    background: props => props.wrapperBg,
  },
  image: {
    maxWidth: "90%",
  },
  textWrapper: {
    position: "relative",
    zIndex: 2,
    marginTop: props => props.text_margin_top,
    padding: props => props.text_padding,
    color: "#595758",
    textAlign: "center",
    fontSize: "1em",
    marginBottom: "20px",

    "& h1": {
      marginBottom: "10px",
      fontSize: "1.5rem",
    },
  },
});

const BlockImagePostViewPhone = ({
  locale,
  data,
  padding_top,
  padding_left,
  padding_bottom,
  padding_right,
  margin_top,
  margin_left,
  margin_bottom,
  margin_right,
  z_index,
  translateX,
  translateY,
  translateZ,
}) => {
  const { background, divider, image_variants } = data;
  const { image, title, description } = getLocaleValue(locale, image_variants);

  const text_margin_left = parseInt(
    margin_left.replace("px", "").replace("%", "").replace("em", "")
  );
  const text_margin_right = parseInt(
    margin_right.replace("px", "").replace("%", "").replace("em", "")
  );

  const classes = useStyles({
    transform: `translateX(${translateX}) translateY(${translateY}) translateZ(${translateZ})`,
    text_margin_top: data
      ? data.name == "new-job-class"
        ? "0%"
        : "-35%"
      : "0px",
    wrapperBg: background,
  });

  return (
    <Box
      width="100%"
      // pt={padding_top}
      // pl={padding_left}
      // pb={padding_bottom}
      // pr={padding_right}
      // mt={margin_top}
      // ml={margin_left}
      // mb={margin_bottom}
      // mr={margin_right}
      // zIndex={z_index}
      className={classes.wrapper}
    >
      <Image loading="eager" className={classes.image} imageObject={image} />
      <Box className={classes.textWrapper}>
        {title && (
          <Box width="100%" className={classes.headerTitle}>
            {Parser(title)}
          </Box>
        )}
        {description && <Box width="100%">{Parser(description)}</Box>}
      </Box>
      {divider && (
        <Box display="flex" justifyContent="center">
          <Image loading="eager" imageObject={divider.backgroundImage} />
        </Box>
      )}
    </Box>
  );
};

export default BlockImagePostViewPhone;
