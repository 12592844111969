import React from "react";
import { Box, Typography } from "@material-ui/core";

import { getLocaleValue } from "../../../helpers/get-locale-value";

import NewsBoardItemController from "./news-board-item-controller";

const NewsBoardView = ({
  locale,
  titleVariants,
  numberPerPage,
  displayTitle,
  anchorLink,
  news,
  margin,
  padding,
  zIndex,
}) => {
  const { padding_top, padding_bottom, padding_left, padding_right } = padding;
  const { margin_top, margin_bottom, margin_left, margin_right } = margin;
  const [expandedPanel, setExpandedPanel] = React.useState("panel0");

  const handleChange = panel => (event, isExpanded) => {
    setExpandedPanel(panel);
  };
  return (
    <Box
      pt={padding_top}
      pb={padding_bottom}
      pl={padding_left}
      pr={padding_right}
      mt={margin_top}
      mb={margin_bottom}
      ml={margin_left}
      mr={margin_right}
      zIndex={zIndex}
    >
      <Typography variant="h4" mb={0}>
        {displayTitle &&
          titleVariants &&
          getLocaleValue(locale, titleVariants).content}
      </Typography>
      {news?.map((n, i) => (
        <NewsBoardItemController
          key={i}
          data={n}
          index={i}
          handleChange={handleChange}
          expandedPanel={expandedPanel}
        />
      ))}
    </Box>
  );
};

export default NewsBoardView;
