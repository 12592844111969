import React from "react";
import { useSelector } from "react-redux";

import NewsBoardItemViewDesktop from "./news-board-item-view-desktop";
import NewsBoardItemViewTablet from "./news-board-item-view-tablet";
import NewsBoardItemViewPhone from "./news-board-item-view-phone";
import ResponsiveWrapper from "../../shared-components/responsive-wrapper";

const NewsBoardItemController = ({
  index,
  data,
  handleChange,
  expandedPanel,
}) => {
  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  return (
    <ResponsiveWrapper
      DesktopComponent={
        <NewsBoardItemViewDesktop
          locale={locale}
          data={data}
          index={index}
          handleChange={handleChange}
          expandedPanel={expandedPanel}
        />
      }
      TabletComponent={
        <NewsBoardItemViewTablet
          locale={locale}
          data={data}
          index={index}
          handleChange={handleChange}
          expandedPanel={expandedPanel}
        />
      }
      PhoneComponent={
        <NewsBoardItemViewPhone
          locale={locale}
          data={data}
          index={index}
          handleChange={handleChange}
          expandedPanel={expandedPanel}
        />
      }
    />
  );
};
export default NewsBoardItemController;
