import React from "react";
import { Box, Grid } from "@material-ui/core";
import Flickity from "react-flickity-component";

import NewsCardsItemController from "./news-cards-item-controller";

import "flickity/css/flickity.css";

const NewsCardsView = ({ locale, cards, margin, padding, zIndex }) => {
  const { padding_top, padding_bottom, padding_left, padding_right } = padding;
  const { margin_top, margin_bottom, margin_left, margin_right } = margin;

  const isGreaterEq4Cards = cards.length >= 4;

  return (
    <Box
      pt={padding_top}
      pb={padding_bottom}
      pl={padding_left}
      pr={padding_right}
      mt={margin_top}
      mb={margin_bottom}
      ml={margin_left}
      mr={margin_right}
      zIndex={zIndex}
    >
      <Grid container>
        <Grid item xs={12}>
          <Flickity
            className={`carousel gallery`} // default ''
            elementType={"div"} // default 'div'
            options={{
              wrapAround: false,
              pageDots: false,
              initialIndex: 0,
              prevNextButtons: isGreaterEq4Cards,
              contain: true,
            }} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false
            static // default false
          >
            {cards?.map((c, i) => (
              <Box className="gallery-cell" key={i}>
                <NewsCardsItemController data={c} />
              </Box>
            ))}
          </Flickity>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewsCardsView;
