import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Parser from "html-react-parser";
import { Box } from "@material-ui/core";
import { getLocaleValue } from "../../../../helpers/get-locale-value";

import Img from "gatsby-image";
import Image from "../../image";

const useStyles = makeStyles({
  wrapper: {
    position: "relative",
    pointerEvents: "none",
    transform: props => props.transform,
    background: props => props.wrapperBg,
  },
  textWrapper: {
    position: "relative",
    zIndex: 2,
    marginTop: props => props.text_margin_top,
    padding: props => props.text_padding,
    marginRight: props => props.text_margin_left,
    marginLeft: props => props.text_margin_right,
    color: "#595758",
    textAlign: "center",
    fontSize: "1em",

    "& h1": {
      marginBottom: "10px",
      fontSize: "1.5rem",
    },
  },
});

const BlockImagePostViewTablet = ({
  locale,
  data,
  padding_top,
  padding_left,
  padding_bottom,
  padding_right,
  margin_top,
  margin_left,
  margin_bottom,
  margin_right,
  z_index,
  translateX,
  translateY,
  translateZ,
}) => {
  const { background, divider, image_variants } = data;
  const { image, title, description } = getLocaleValue(locale, image_variants);

  const text_margin_left = parseInt(
    margin_left.replace("px", "").replace("%", "").replace("em", "")
  );
  const text_margin_right = parseInt(
    margin_right.replace("px", "").replace("%", "").replace("em", "")
  );

  const classes = useStyles({
    transform: `translateX(${translateX}) translateY(${translateY}) translateZ(${translateZ})`,

    text_margin_left:
      text_margin_left > 0
        ? margin_left
        : text_margin_right > 0
        ? -(text_margin_right - 20)
        : 0,

    text_margin_right:
      text_margin_right > 0
        ? margin_right
        : text_margin_left > 0
        ? -(text_margin_left - 20)
        : 0,

    text_margin_top: data
      ? data.name == "new-job-class"
        ? "-5%"
        : "-38%"
      : "0px",

    text_padding: "0px 10px",
    wrapperBg: background,
  });

  return (
    <Box
      width="100%"
      pt={padding_top}
      pl={padding_left}
      pb={padding_bottom}
      pr={padding_right}
      mt={margin_top}
      ml={margin_left}
      mb={margin_bottom}
      mr={margin_right}
      zIndex={z_index}
      className={classes.wrapper}
    >
      <Image loading="eager" className={classes.image} imageObject={image} />
      <Box className={classes.textWrapper}>
        {title && (
          <Box width="100%" className={classes.headerTitle}>
            {Parser(title)}
          </Box>
        )}
        {description && <Box width="100%">{Parser(description)}</Box>}
      </Box>
      {divider && (
        <Box display="flex" justifyContent="center">
          <Image loading="eager" imageObject={divider.backgroundImage} />
        </Box>
      )}
    </Box>
  );
};

export default BlockImagePostViewTablet;
